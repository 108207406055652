import { faCamera, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { Component } from 'react';
import defaultProfileImage from '../../images/profile-icon.png';


axios.defaults.withCredentials = true;

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseSchema: {
        name: '',
        phone: '',
        password: '',
        age: '',
        gender: '',
        bloodGroup: '',
        medHistory: '',
        area: '',
        allergy: '',
        occupation: '',
        address: '',
        profileImage: defaultProfileImage,
      },
      members: [],
      errors: {},
      maxMembers: 5,
      editing: false,
      showPopup: false,
      popupMessage: '',
      memberId: null,
      expandedFields: [],
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  checkSession = async () => {
    try {
      const response = await axios.get('https://api-raj.trailique.ai/api/userApi/check-session', { withCredentials: true });
      if (response.data.isAuthenticated) {
        this.setState({ isAuthenticated: true });
      } else {
        this.setState({ isAuthenticated: false });
      }
    } catch (error) {
      console.error('Error checking session:', error);
      this.setState({ isAuthenticated: false });
    }
  };

  fetchUserData = async () => {
    try {
      const sessionResponse = await axios.get('https://api-raj.trailique.ai/api/userApi/check-session', { withCredentials: true });
      if (sessionResponse.status === 200 && sessionResponse.data.isAuthenticated) {
        const userId = sessionResponse.data.user._id;
  
        // Fetch user's base profile data
        const userInfoResponse = await axios.get(`https://api-raj.trailique.ai/api/userApi/${userId}`);
        if (userInfoResponse.status === 200) {
          const userData = userInfoResponse.data;
          this.setState({
            baseSchema: {
              ...this.state.baseSchema,
              name: userData.name,
              phone: userData.phoneNumber,
              age: userData.age,
              gender: userData.gender,
              bloodGroup: userData.bloodGroup,
              medHistory: userData.medHistory,
              area: userData.area,
              allergy: userData.allergy,
              occupation: userData.occupation,
              address: userData.address,
              profileImage: userData.profileImage || defaultProfileImage,
            },
          });
        } else {
          console.error('Failed to fetch user info:', userInfoResponse.statusText);
        }
  
        // Fetch family members associated with the user
        const familyMembersResponse = await axios.get(`https://api-raj.trailique.ai/api/familyMembers/user/${userId}`);
        if (familyMembersResponse.status === 200) {
          const familyMembersData = familyMembersResponse.data;
          this.setState({ members: familyMembersData });
        } else {
          console.error('Failed to fetch family members:', familyMembersResponse.statusText);
        }
      } else {
        console.error('User is not authenticated.');
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

// Assuming this is inside your React component class or function
editFamilyMember = async (userId, documentId, updatedData) => {
  try {
    const response = await axios.put(`https://api-raj.trailique.ai/api/familyMembers/user/${userId}/${documentId}`, updatedData);
    if (response.status === 200) {
      console.log('Family member updated successfully:', response.data);
      // Optionally update state or handle success
    } else {
      console.error('Failed to update family member:', response.statusText);
    }
  } catch (error) {
    console.error('Error updating family member:', error);
  }
};

  
  editBaseUserProfile = async (userId, updatedData) => {
    try {
      const response = await axios.put(`https://api-raj.trailique.ai/api/userApi/${userId}`, updatedData);
      if (response.status === 200) {
        // Update state or handle success
        console.log('Base user profile updated successfully:', response.data);
      } else {
        console.error('Failed to update base user profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating base user profile:', error);
    }
  };


  // Function to delete a family member by _id
deleteFamilyMember = async (userId, documentId) => {
  try {
    const response = await axios.delete(`https://api-raj.trailique.ai/api/familyMembers/user/${userId}/${documentId}`);
    if (response.status === 200) {
      console.log('Family member deleted successfully:', response.data);
    } else {
      console.error('Failed to delete family member:', response.statusText);
      throw new Error('Delete operation failed');
    }
  } catch (error) {
    console.error('Error deleting family member:', error.message);
    throw error; // Propagate the error for the caller to handle
  }
};

  addMember = () => {
    const { members, maxMembers } = this.state;

    if (members.length < maxMembers) {
      const newMember = {
        name: '',
        phone: '',
        age: '',
        gender: '',
        bloodGroup: '',
        medHistory: '',
        area: '',
        allergy: '',
        occupation: '',
        profileImage: defaultProfileImage,
      };

      this.setState((prevState) => ({
        members: [...prevState.members, newMember],
      }));
    } else {
      alert(`Maximum ${maxMembers} members allowed.`);
    }
  };

  handleInputChange = (field, value, index = null) => {
    if (index !== null) {
      const updatedMembers = [...this.state.members]; // Create a shallow copy of members array
      updatedMembers[index][field] = value; // Update the specific member's field
      this.setState({ members: updatedMembers, editing: true }); // Set updated state
    } else {
      const updatedBaseSchema = { ...this.state.baseSchema }; // Create a shallow copy of baseSchema
      updatedBaseSchema[field] = value; // Update the base schema field
      this.setState({ baseSchema: updatedBaseSchema, editing: true }); // Set updated state
    }
  };
  
  

  validateForm = (index) => {
    const errors = {};
    const { members, baseSchema } = this.state;
    const member = index !== null ? members[index] : baseSchema;

    if (!member.name) errors[`name${index !== null ? index : ''}`] = 'Name is required';
    if (!member.phone) errors[`phone${index !== null ? index : ''}`] = 'Phone number is required';
    else if (!/^\d+$/.test(member.phone) || member.phone.length !== 10) errors[`phone${index !== null ? index : ''}`] = 'Phone Number should be 10 digits';
    if (!/^\d+$/.test(member.age)) errors[`age${index !== null ? index : ''}`] = 'Age should be a number';
    else if (member.age <= 0) errors[`age${index !== null ? index : ''}`] = 'Age should be a positive number';

    this.setState((prevState) => ({
      errors: { ...prevState.errors, ...errors },
    }));

    return Object.keys(errors).length === 0;
  };

  toggleEdit = (index) => {
    const { expandedFields } = this.state;
    let newExpandedFields = [...expandedFields];

    if (newExpandedFields.includes(index)) {
      newExpandedFields = newExpandedFields.filter((item) => item !== index);
    } else {
      newExpandedFields.push(index);
    }

    this.setState({ expandedFields: newExpandedFields });
  };


handleSubmit = async (index) => {
  console.log('Handling submit...');

  try {
    const sessionResponse = await axios.get('https://api-raj.trailique.ai/api/userApi/check-session', { withCredentials: true });
    if (sessionResponse.status === 200 && sessionResponse.data.isAuthenticated) {
      const userId = sessionResponse.data.user._id;

      if (index === null) {
        // Update base profile if index is null
        const baseProfileData = { ...this.state.baseSchema };
        await this.editBaseUserProfile(userId, baseProfileData);
      } else {
        // Update or add family member if index is not null
        const memberProfileData = { ...this.state.members[index] };

        if (memberProfileData._id) {
          // Existing family member, update
          await this.editFamilyMember(userId, memberProfileData._id, memberProfileData);
        } else {
          // New family member, add
          const addedMember = await this.addFamilyMember(userId, memberProfileData);
          if (addedMember) {
            // Update state with the newly added family member
            const updatedMembers = [...this.state.members];
            updatedMembers[index] = addedMember;
            this.setState({ members: updatedMembers });
          }
        }
      }

      alert('Profile updated successfully!');
      window.location.reload();
    } else {
      console.error('User is not authenticated.');
      alert('User is not authenticated. Please log in.');
    }
  } catch (error) {
    console.error('Error updating profile:', error);
    alert('Failed to update profile. Please try again.');
  }
};


addFamilyMember = async (userId, newFamilyMemberData) => {
  try {
    const response = await axios.post(`https://api-raj.trailique.ai/api/familyMembers/user/${userId}`, newFamilyMemberData);
    if (response.status === 201) {
      // Update state or handle success
      console.log('Family member added successfully:', response.data);
      this.setState((prevState) => ({
        members: [...prevState.members, response.data],
      }));
    } else {
      console.error('Failed to add family member:', response.statusText);
    }
  } catch (error) {
    console.error('Error adding family member:', error);
  }
};

deleteFamilyMember = async (userId, documentId) => {
  try {
    const response = await axios.delete(`https://api-raj.trailique.ai/api/familyMembers/user/${userId}/${documentId}`);
    if (response.status === 200) {
      console.log('Family member deleted successfully:', response.data);
      // Remove the deleted member from state
      const updatedMembers = this.state.members.filter(member => member._id !== documentId);
      this.setState({ members: updatedMembers });
    } else {
      console.error('Failed to delete family member:', response.statusText);
      throw new Error('Delete operation failed');
    }
  } catch (error) {
    console.error('Error deleting family member:', error.message);
    alert('Failed to delete family member. Please try again.');
  }
};




handleDeleteMember = async (userId, memberId) => {
  console.log('Deleting member:', userId, memberId); // Log userId and memberId
  try {
    const response = await axios.delete(`https://api-raj.trailique.ai/api/familyMembers/user/${userId}/${memberId}`);
    // ...
  } catch (error) {
    console.error('Error deleting family member:', error.message);
    alert('Failed to delete family member. Please try again.');
  }
};





handleEditBaseProfile = () => {
  this.setState(prevState => ({ isBaseProfileEditable: !prevState.isBaseProfileEditable }));
}

renderForm = (member, index) => {
  const { name, phone, occupation, allergy, medHistory, area, bloodGroup, profileImage, age, gender, address } = member;
  const { errors, isBaseProfileEditable } = this.state;

  const isBaseProfile = index === null;

  return (
    <div className="row" key={index} style={{ marginTop: '20px', marginBottom: '20px' }}>
      <div className="col-12">
        <h5>
          {isBaseProfile ? 'Base Profile' : `Member ${index + 1}`}
          {!isBaseProfile && (
             <span style={{ float: 'right', cursor: 'pointer', color: 'red' }} onClick={() => this.handleDeleteMember(member.userId, member._id)}>
             <FontAwesomeIcon icon={faTrash} />
           </span>
          )}
             <span style={{ float: 'right', cursor: 'pointer', color: '#007BFF', marginRight: '10px' }} onClick={() => this.toggleEdit(index)}></span>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ float: 'right', cursor: 'pointer', marginRight:'10px' }}
              onClick={this.handleEditBaseProfile}
            />
        </h5>
      </div>
      {/* <div className="col-12">
        <h5>
          {isBaseProfile ? 'Base Profile' : `Member ${index + 1}`}
          {!isBaseProfile && (
            <span style={{ float: 'right', cursor: 'pointer', color: 'red' }} onClick={() => this.handleDeleteMember(member.userId, member._id)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>
          )}
          {isBaseProfile && (
            <FontAwesomeIcon
              icon={faEdit}
              style={{ float: 'right', cursor: 'pointer', marginRight: '10px' }}
              onClick={this.handleEditBaseProfile}
            />
          )}
        </h5>
      </div> */}

      {isBaseProfile && (
        <div className="col-md-3 text-center mb-4" style={{ marginTop: '20px' }}>
          <div style={{
            position: 'relative',
            width: '200px',
            height: '200px',
            margin: '0 auto',
          }}
            
          >
            <input
              type="file"
              id={`profileImageInput${index}`}
              style={{ display: 'none' }}
              onChange={(event) => this.handleImageChange(index, event)}
            />
          </div>
        </div>
      )}


      <div className={`col-md-${isBaseProfile ? '9' : '12'}`}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Name:</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => this.handleInputChange('name', e.target.value, index)}
              placeholder="Name"
              required
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              readOnly={isBaseProfile && !isBaseProfileEditable}
            />
            {errors[`name${index !== null ? index : ''}`] && <div className="invalid-feedback">{errors[`name${index !== null ? index : ''}`]}</div>}
          </div>
          {isBaseProfile && (
            <div className="col-md-6 mb-3">
              <label>Phone:</label>
              <input
                type="text"
                className="form-control"
                value={phone}
                onChange={(e) => this.handleInputChange('phone', e.target.value, index)}
                placeholder="Phone"
                required
                style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
                readOnly={!isBaseProfileEditable}
              />
              {errors[`phone${index !== null ? index : ''}`] && <div className="invalid-feedback">{errors[`phone${index !== null ? index : ''}`]}</div>}
            </div>
          )}

          <div className="col-md-6 mb-3">
            <label>Occupation:</label>
            <input
              type="text"
              className="form-control"
              value={occupation}
              onChange={(e) => this.handleInputChange('occupation', e.target.value, index)}
              placeholder="Occupation"
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              readOnly={isBaseProfile && !isBaseProfileEditable}
            />
          </div>
          {/* <div className="col-md-6 mb-3">
            <label>Age:</label>
            <input
              type="number"
              className="form-control"
              value={age}
              onChange={(e) => this.handleInputChange('age', e.target.value, index)}
              placeholder="Age"
              required
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              readOnly={isBaseProfile && !isBaseProfileEditable}
            />
            {errors[`age${index !== null ? index : ''}`] && <div className="invalid-feedback">{errors[`age${index !== null ? index : ''}`]}</div>}
          </div> */}
          <div className="col-md-6 mb-3">
          <label>
            Age:<span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="number"
            className="form-control"
            value={age}
            onChange={(e) => this.handleInputChange('age', e.target.value, index)}
            placeholder="Age"
            required
            style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
            readOnly={isBaseProfile && !isBaseProfileEditable}
            // To remove the spin buttons in Chrome, Safari, Edge, and Firefox
            inputMode="numeric"
            pattern="[0-9]*"
            min="0"
          />
          {errors[`age${index !== null ? index : ''}`] && <div className="invalid-feedback">{errors[`age${index !== null ? index : ''}`]}</div>}
        </div>

          <div className="col-md-6 mb-3">
            <label>Gender:</label>
            <select
              className="form-control"
              value={gender}
              onChange={(e) => this.handleInputChange('gender', e.target.value, index)}
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              disabled={isBaseProfile && !isBaseProfileEditable}
            >
              <option value="" disabled>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label>Blood Group:</label>
            <select
              className="form-control"
              value={bloodGroup}
              onChange={(e) => this.handleInputChange('bloodGroup', e.target.value, index)}
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              disabled={isBaseProfile && !isBaseProfileEditable}
            >
              <option value="" disabled>Select Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
            </select>
          </div>

          <div className="col-md-6 mb-3">
            <label>Allergy:</label>
            <input
              type="text"
              className="form-control"
              value={allergy}
              onChange={(e) => this.handleInputChange('allergy', e.target.value, index)}
              placeholder="Allergy"
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              readOnly={isBaseProfile && !isBaseProfileEditable}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Medical History:</label>
            <input
              type="text"
              className="form-control"
              value={medHistory}
              onChange={(e) => this.handleInputChange('medHistory', e.target.value, index)}
              placeholder="Medical History"
              style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
              readOnly={isBaseProfile && !isBaseProfileEditable}
            />
          </div>

          {index === null && (
            <div className="col-md-6 mb-3">
              <label>Address:</label>
              <input
                type="text"
                className="form-control"
                value={address}
                onChange={(e) => this.handleInputChange('address', e.target.value, index)}
                placeholder="Address"
                style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
                readOnly={!isBaseProfileEditable}
              />
            </div>
          )}
          {isBaseProfile && (
            <div className="col-md-6 mb-3">
              <label>Area:</label>
              <input
                type="text"
                className="form-control"
                value={area}
                onChange={(e) => this.handleInputChange('area', e.target.value, index)}
                placeholder="Area"
                style={{ borderColor: '#BFBFBF', color: '#273F61', width: '100%' }}
                readOnly={!isBaseProfileEditable}
              />
            </div>
          )}
        </div>
      </div>
      
      {isBaseProfile && isBaseProfileEditable && (
        <div className="col-12 d-flex justify-content-center">
          {/* <button
            onClick={() => this.handleSubmit(index)}
            style={{
              borderRadius: '5px',
              width: '130px',
              height: '40px',
              border: 'none',
              backgroundColor: '#FF7A00',
              fontSize: '0.9rem',
              color: '#FFFFFF',
              marginTop: '20px',
              fontWeight: 'bold'
            }}>
            Update
          </button> */}
        </div>
      )}
      <button
            onClick={() => this.handleSubmit(index)}
            style={{
              borderRadius: '5px',
              width: '130px',
              height: '40px',
              border: 'none',
              backgroundColor: '#FF7A00',
              fontSize: '0.9rem',
              color: '#FFFFFF',
              marginTop: '50px',
              fontWeight: 'bold',
              marginLeft:'450px'
            }}>
            Update
          </button>
    </div>
  );
};

render() {
  const { members, baseSchema } = this.state;

  return (
    <div className="container" style={{ marginTop: '100px', marginBottom: '60px' }}>
      <div className="column">
        <div className="col-12">
          <h4>Edit Profile</h4>
          <hr />
        </div>
        {this.renderForm(baseSchema, null)}
        {members.map((member, index) => this.renderForm(member, index))}
        <div className="col-12 d-flex justify-content-center">
          <button
    onClick={this.addMember}
    style={{
      borderRadius: '5px',
      width: '130px',
      height: '43px',
      border: 'none',
      backgroundColor: '#FF7A00',
      fontSize: '0.9rem',
      color: '#FFFFFF',
      marginLeft: '150px',
      fontWeight: 'bold',
      marginTop:'-60px'
    }}
  >
    Add Member
  </button>
        </div>
      </div>
    </div>
  );
}
}

export default EditProfile;