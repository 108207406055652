import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Image from '../../images/signIn.png';

function NewPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const validatePassword = (password) => {
        // Example: Password must be at least 8 characters long, contain at least one number and one special character
        const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let isValid = true;

        if (!validatePassword(password)) {
            setPasswordError('Password must be at least 8 characters long and contain at least one number and one special character.');
            isValid = false;
        } else {
            setPasswordError('');
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            isValid = false;
        } else {
            setConfirmPasswordError('');
        }

        if (!isValid) return;

        setError(''); 

        try {
            const response = await axios.post('http://localhost:4000/login', { password });
            console.log(response.data);
            setSuccessMessage('Password reset successfully.');
            setTimeout(() => navigate('/'), 3000);
        } catch (error) {
            console.error('Error:', error);
            console.error('Error Response:', error.response);
            if (error.response && error.response.data) {
                setError(error.response.data.message);
            } else {
                setError('An unexpected error occurred');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setPassword(value);
            setPasswordError('');
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
            if (password !== value) {
                setConfirmPasswordError('Passwords do not match.');
            } else {
                setConfirmPasswordError('');
            }
        }
    };

    const handleClick = () => {
        if (isFormValid) {
            navigate('/');
        }
    };

    const isMobileDevice = () => {
        return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    };


    const isFormValid = password && confirmPassword;

    return (
        <div className="container mt-5">
            <div className="row justify-content-center" style={{ marginBottom: '30px' }}>
            {!isMobileDevice() && (
                <div className="col-lg-6" style={{ marginBottom: '20px' }}>
                    <img src={Image} alt="Doctor" className="img-fluid d-block mx-auto mb-4" style={{ maxWidth: '100%' }} />
                </div>
                  )}
                <div className="col-lg-5 col-md-8">
                    <div className="appointment-form form-wraper mt-16" style={{marginTop:'100px'}}>
                        <form onSubmit={handleSubmit}>
                            <h3 className="text-center mb-4">Reset Password</h3>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className={`form-control ${passwordError && 'is-invalid'}`}
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    required
                                />
                                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className={`form-control ${confirmPasswordError && 'is-invalid'}`}
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                                {confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn btn-block"
                                    style={{
                                        backgroundColor: isFormValid ? '#fa9225' : '#fa9225',
                                        color: isFormValid ? 'white' : 'white',
                                        width: '35%',
                                        height: '45px',
                                        fontWeight: 'bold',
                                        border: 'none'
                                    }}
                                    disabled={!isFormValid}
                                    onClick={handleClick}
                                >
                                   Login
                                </button>
                            </div>
                            {successMessage && <div className="alert alert-success">{successMessage}</div>}
                            {error && <div className="alert alert-danger">{error}</div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewPassword;
