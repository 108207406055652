import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Image from '../../images/booking.jpg';
import AppointmentForm from '../elements/appointment-form';

const Booking = () => {
  const [showModal, setShowModal] = useState(false);
  const [tokenNumber, setTokenNumber] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userId, setUserId] = useState('');
  const [familyMembers, setFamilyMembers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const sessionResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/userApi/check-session`, { withCredentials: true });
        if (sessionResponse.status === 200 && sessionResponse.data.isAuthenticated) {
          const userId = sessionResponse.data.user._id;
          setUserId(userId);

          const userInfoResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/userApi/${userId}`);
          if (userInfoResponse.status === 200) {
            setUserInfo(userInfoResponse.data);
            setIsLoggedIn(true);
            fetchFamilyMembers(userId);
          } else {
            console.error('Failed to fetch user info:', userInfoResponse.statusText);
            setIsLoggedIn(false);
          }
        } else {
          console.error('User is not authenticated.');
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        setIsLoggedIn(false);
      }
    };

    checkAuthentication(); // Call checkAuthentication here

  }, []); // Empty dependency array means it only runs once on mount

  const fetchFamilyMembers = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/familyMembers/user/${userId}`);
      if (response.status === 200) {
        setFamilyMembers(response.data);
      } else {
        console.error('Failed to fetch family members:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching family members:', error);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const { name, phone, date, familyMemberId } = formData;
      const formattedDate = new Date(date).toISOString();

      const response = await axios.post('https://api-raj.trailique.ai/api/booking/book', {
        name,
        phone,
        userId,
        date: formattedDate,
        familyMemberId,
      });

      if (response.status === 201) {
        setShowModal(true);
        setTokenNumber(response.data.token);
      } else {
        console.error('Booking failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/token', { state: { token: tokenNumber } });
  };

  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.includes('android');
  const isIOS = /iphone|ipad|ipod/i.test(userAgent);

  if (!isLoggedIn) {
    return (
      <div className="container text-center" style={{ marginTop: '100px' }}>
        <h3>You need to log in to access this page.</h3>
        <Button variant="primary" onClick={() => navigate('/form-login')}>
          Go to Login
        </Button>
      </div>
    );
  }

  return (
    <div className="page-content bg-white">
      <div className="container">
        <section className="section-area section-sp2 appointment-wraper" style={{ marginTop: '5rem' }}>
          <div className="row">
            {!(isAndroid || isIOS) && (
              <div className="col-lg-6">
                <img src={Image} alt="Doctor" className="img-fluid d-block mx-auto mb-4" style={{ marginTop: '1rem', maxWidth: '100%' }} />
              </div>
            )}
            <div className={(isAndroid || isIOS) ? 'col-lg-12' : 'col-lg-6'} style={{ marginTop: '80px', maxWidth: '500px', margin: '0 auto' }}>
              {userInfo && (
                <AppointmentForm
                  userName={userInfo.name}
                  userPhoneNumber={userInfo.phoneNumber}
                  userInfo={userInfo}
                  userId={userId}
                  familyMembers={familyMembers}
                  onSubmit={handleSubmit}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Booking Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your appointment has been booked successfully!</p>
          <p>Your token number: <strong>{tokenNumber}</strong></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Booking;
