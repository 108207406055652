import React, { Component } from 'react';
import medical from '../../images/work/awards.jpeg';
import prescription from '../../images/work/certificate.webp';
import syringe from '../../images/work/licenc.png';

class AboutSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorDetails: null
        };
    }

    componentDidMount() {
        this.fetchDoctorDetails();
    }

    fetchDoctorDetails() {
        fetch('https://api-raj.trailique.ai/api/doctors/get-details') // Update URL if needed
            .then(response => response.json())
            .then(data => {
                console.log('Doctor details:', data); // Log the received data to inspect the structure
                if (data && data.length > 0) {
                    // Decode base64 photo
                    const decodedData = this.decodeBase64(data[0].photo);
                    data[0].photo = decodedData;
                    this.setState({ doctorDetails: data[0] }); // Assuming you're fetching a single doctor's details
                }
            })
            .catch(error => console.error('Error fetching doctor details:', error));
    }

    // Function to decode base64 string
    decodeBase64(base64String) {
        const decodedString = atob(base64String);
        const uint8Array = new Uint8Array(decodedString.length);
        for (let i = 0; i < decodedString.length; i++) {
            uint8Array[i] = decodedString.charCodeAt(i);
        }
        return URL.createObjectURL(new Blob([uint8Array]));
    }

    render() {
        const { doctorDetails } = this.state;

        const containerStyle = {
            padding: '20px'
        };

        const doctorImageStyle = {
            textAlign: 'center'
        };

        const doctorImageInnerStyle = {
            maxWidth: '100%',
            height: 'auto'
        };

        const textContainerStyle = {
            marginTop: '20px',
            textAlign: 'center'
        };

        const additionalImagesStyle = {
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            marginTop: '20px'
        };

        const responsiveImageStyle = {
            width: '100%',
            maxWidth: '150px',
            height: 'auto',
            marginBottom: '10px'
        };

        const verticalImagesStyle = {
            display: 'block',
            marginBottom: '10px'
        };

        return (
            <div className="card about-area" style={containerStyle}>
                <div className="doctor-image about-thumb-area" style={doctorImageStyle}>
                    {doctorDetails && <img src={doctorDetails.photo} alt="Doctor" className="about-thumb1" style={doctorImageInnerStyle} />}
                </div>
                <div className="text-container" style={textContainerStyle}>
                    {doctorDetails && (
                        <>
                            <h2>{doctorDetails.name}</h2>
                            <p>
                                <span className="title">{doctorDetails.speciality}</span>
                            </p>
                            <p className="description">
                                {doctorDetails.briefDescription} {doctorDetails.testimonials && doctorDetails.testimonials.map(testimonial => testimonial.text).join(' ')}
                            </p>
                        </>
                    )}
                    <div className="additional-images" style={additionalImagesStyle}>
                        <img src={medical} alt="Medical" className="responsive-image" style={responsiveImageStyle} />
                        <img src={prescription} alt="Prescription" className="responsive-image" style={responsiveImageStyle} />
                        <img src={syringe} alt="Syringe" className="responsive-image" style={responsiveImageStyle} />
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutSection;
