import React from 'react';
import { Outlet } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './App.scss';
import useSessionTimeout from "./hooks/useSessionTimeout";
import Markup from './markup/markup';
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/themify/themify-icons.css";


function App() {
  // Call useSessionTimeout to start monitoring session
  useSessionTimeout();

  return (
      <div className="page-wraper">
        <Markup />
        <Outlet />
      </div>

  );
}

export default App;
