import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sticky from 'react-stickynode';
import logoWhite from '../../images/logo-white.png';
import logo from '../../images/logo.png';
axios.defaults.withCredentials = true;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth >= 768);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const navigate = useNavigate();

  const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android')) {
      return 'android';
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      return 'ios';
    } else if (userAgent.includes('mobile')) {
      return 'mobile';
    }
    return 'desktop';
  };

  const deviceType = getDeviceType();

  const toggleSubmenu = (item) => {
    setActiveItem(item === activeItem ? null : item);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleMenuLinkClick = () => {
    if (window.innerWidth <= 991) {
      setIsMenuOpen(false);
    }
  };

  const handleMenuCloseClick = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await axios.get('https://api-raj.trailique.ai/api/userApi/logout', { withCredentials: true });
      setIsAuthenticated(false);
      setUser(null);
      setIsLoggedOut(true);
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const fetchToken = async () => {
    try {
      const response = await axios.get('https://api-raj.trailique.ai/api/adminToken/get-token', { withCredentials: true });
      setCurrentToken(response.data.value);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get(`https://api-raj.trailique.ai/api/userApi/check-session`, { withCredentials: true });
        setIsAuthenticated(response.data.isAuthenticated);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };
    checkSession();
  }, [isLoggedOut]);

  useEffect(() => {
    fetchToken();
    const interval = setInterval(fetchToken, 4000);
    return () => clearInterval(interval);
  }, []);

  const menuItems = [
    {
      id: 'home',
      name: 'Home',
      linkName: 'home',
      style: { color: 'black' },
    },
    {
      id: 'services',
      name: 'Services',
      linkName: 'services',
      style: { color: 'black' },
    },
    {
      id: 'pages',
      name: isAuthenticated ? 'Profile' : 'Login',
      linkName: isAuthenticated ? 'myprofile' : 'form-login',
      subItems: isAuthenticated
        ? [
            { id: 'MyProfile', displayName: 'My Profile', linkName: 'myprofile' },
            { id: 'MyAppointments', displayName: 'Appointment History', linkName: 'myappointment' },
            { id: 'SignOut', displayName: 'Sign Out', linkName: 'signout', onClick: handleLogout },
          ]
        : [
            { id: 'formLogin', displayName: 'Login', linkName: 'form-login' },
            { id: 'formRegister', displayName: 'Register', linkName: 'form-register' },
          ],
      style: { color: 'black' },
    },
    {
      id: 'bookAppointment',
      name: 'Book Appointment',
      linkName: 'bookappointment',
      isButton: true,
    },
  ];

  return (
    <header className="header header-transparent rs-nav">
      <Sticky enabled={true} className="sticky-header navbar-expand-lg">
        <div className="menu-bar clearfix">
          <div className="container clearfix" style={{ paddingRight: '50px' }}>
            <div className="menu-logo logo-dark">
              <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                <img src={logo} alt="Logo" style={{ width: '250px', margin: '10px', marginTop: '10px', marginLeft: '-40px' }} />
                <button
                  style={{
                    backgroundColor: '#FF7A00',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    padding: deviceType === 'android' ? '10px' : deviceType === 'ios' ? '8px' : '5px',
                    width: deviceType === 'android' ? '500px' : deviceType === 'ios' ? '450px' : '350px',
                    height: deviceType === 'android' ? '45px' : deviceType === 'ios' ? '40px' : '35px',
                    fontSize: '15px',
                    marginLeft: '10px',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    marginTop: '-10px',
                  }}
                >
                  {deviceType === 'android'
                    ? currentToken !== null ? currentToken : 'Loading...'
                    : deviceType === 'ios'
                    ? currentToken !== null ? currentToken : 'Loading...'
                    : `Current Token: ${currentToken !== null ? currentToken : 'Loading...'}`}
                </button>
              </Link>
            </div>

            <button
              className={`navbar-toggler collapsed menuicon justify-content-end ${isMenuOpen ? 'open' : ''}`}
              type="button"
              onClick={toggleMenu}
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div className={`menu-links navbar-collapse collapse justify-content-end ${isMenuOpen ? 'show' : ''}`} id="menuDropdown">
              <div className="menu-logo">
                <Link to="/">
                  <img src={logoWhite} alt="Logo White" />
                </Link>
              </div>

              <ul className="nav navbar-nav">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className={`${activeItem === item.id ? 'open' : ''}`}
                    onClick={() => toggleSubmenu(item.id)}
                    style={{ color: item.style?.color }}
                  >
                    {item.isButton ? (
                      <Link to={`/${item.linkName}`} onClick={handleMenuLinkClick}>
                        <button
                          style={{
                            backgroundColor: '#FF7A00',
                            color: '#ffffff',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            marginTop: '-20px',
                          }}
                        >
                          {item.name}
                        </button>
                      </Link>
                    ) : item.subItems ? (
                      <Link to="#" style={{ color: item.style?.color }}>
                        {item.name}
                        <i className="fas fa-plus"></i>
                      </Link>
                    ) : (
                      <Link to={`/${item.linkName}`} onClick={handleMenuLinkClick} style={{ color: item.style?.color }}>
                        {item.name}
                      </Link>
                    )}
                    {(isMobileView || activeItem === item.id) && item.subItems && (
                      <ul className="sub-menu">
                        {item.subItems.map((subItem) => (
                          <li key={subItem.id}>
                            <Link
                              to={`/${subItem.linkName}`}
                              onClick={subItem.onClick ? subItem.onClick : handleMenuLinkClick}
                              style={{ color: item.style?.color }}
                            >
                              {subItem.displayName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>

              <div className="menu-close" onClick={handleMenuCloseClick}>
                <i className="ti-close"></i>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  );
};

export default Header;
