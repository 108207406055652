import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../images/signIn.png';
import Footer from '../layout/footer';
import Header from '../layout/header';

axios.defaults.withCredentials = true;

function FormLogin() {
    const [phone, setPhone] = useState('+91');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [otpRequested, setOtpRequested] = useState(false); 
    const [resendDisabled, setResendDisabled] = useState(false); 
    const [countdown, setCountdown] = useState(60); 
    const navigate = useNavigate();

    useEffect(() => {
        async function checkSession() {
            try {
                const response = await axios.get('https://api-raj.trailique.ai/api/userApi/check-session');
                if (response.status === 200 && response.data.isAuthenticated) {
                    navigate('/myprofile'); 
                }
            } catch (error) {
                console.error('Error checking session:', error);
            }
        }

        checkSession();
    }, [navigate]);

    axios.defaults.withCredentials = true;

    const handleLogin = async () => {
        try {
            // Validate phone number
            const phoneRegex = /^\+91[1-9]\d{9}$/;
            if (!phoneRegex.test(phone)) {
                setError('Invalid phone number. It must be +91 followed by 10 digits.');
                return;
            }

            // Request OTP generation
            const response = await axios.post('https://api-raj.trailique.ai/api/userApi/login', {
                phoneNumber: phone,
            });

            if (response.status !== 200) {
                setError(response.data.error || 'Failed to request OTP.');
                return;
            }

            setError('');
            setOtpRequested(true); 
            startResendCountdown(); 
        } catch (error) {
            console.error('Error requesting OTP:', error);
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Network error occurred.');
            }
        }
    };

    const handleVerifyOTP = async () => {
        try {
            // Validate OTP format
            const otpRegex = /^\d{6}$/;
            if (!otpRegex.test(otp)) {
                setError('Invalid OTP. It must be a 6-digit number.');
                return;
            }

            // Verify OTP
            const response = await axios.post('https://api-raj.trailique.ai/api/userApi/verify-otp', {
                phoneNumber: phone,
                otp: otp,
            });

            if (response.status !== 200) {
                setError(response.data.error || 'Failed to verify OTP.');
                return;
            }

            navigate('/myprofile');
        } catch (error) {
            console.error('Error verifying OTP:', error);
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Network error occurred.');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            let formattedValue = value.replace(/\D/g, '');
            if (!formattedValue.startsWith('91')) {
                formattedValue = '91' + formattedValue;
            }
            formattedValue = '+' + formattedValue.slice(0, 12);

            const phoneNumber = formattedValue.slice(3); // Remove +91 for validation
            const phoneRegex = /^[1-9]\d{9}$/;
            setPhone(formattedValue);
            setError(phoneRegex.test(phoneNumber) ? '' : 'Invalid Phone number. It must be 10 digits long.');
        } else if (name === 'otp') {
            setOtp(value);
        }
    };

    const startResendCountdown = () => {
        setResendDisabled(true); 
        setCountdown(60); 
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    clearInterval(interval);
                    setResendDisabled(false); 
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const handleResendOTP = async () => {
        try {
            // Request OTP generation again
            const response = await axios.post('https://api-raj.trailique.ai/api/userApi/login', {
                phoneNumber: phone,
            });

            if (response.status !== 200) {
                setError(response.data.error || 'Failed to request OTP.');
                return;
            }

            setError('');
            startResendCountdown(); 
        } catch (error) {
            console.error('Error resending OTP:', error);
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Network error occurred.');
            }
        }
    };

    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes('android');
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    return (
        <div className="container mt-5">
            <Header />
            <div className="row justify-content-center">
                {!(isAndroid || isIOS) && (
                    <div className="col-lg-6" style={{ marginBottom: '20px' }}>
                        <img
                            src={Image}
                            alt="Doctor"
                            className="img-fluid d-block mx-auto mb-4"
                            style={{ maxWidth: '100%' }}
                        />
                    </div>
                )}
                <div
                    className={isAndroid || isIOS ? 'col-lg-12' : 'col-lg-5 col-md-8'}
                    style={{ marginTop: isAndroid || isIOS ? '130px' : '6rem' }}
                >
                    <div className="appointment-form form-wraper mt-16">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <h3 className="text-center mb-4">Login with OTP</h3>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`form-control ${error && 'is-invalid'}`}
                                    placeholder="Phone Number"
                                    name="phone"
                                    value={phone}
                                    onChange={handleChange}
                                    required
                                />
                                {error && <div className="invalid-feedback">{error}</div>}
                            </div>
                            {!otpRequested && (
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-block"
                                        style={{
                                            color: '#fff',
                                            height: '40px',
                                            padding: '8px 20px',
                                            fontSize: '16px',
                                        }}
                                        onClick={handleLogin}
                                    >
                                        Request OTP
                                    </button>
                                </div>
                            )}
                            {otpRequested && (
                                <>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="OTP"
                                            name="otp"
                                            value={otp}
                                            onChange={handleChange}
                                            required
                                        />
                                        {error && <div className="invalid-feedback">{error}</div>}
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="button"
                                            className="btn btn-warning btn-block"
                                            style={{
                                                color: '#fff',
                                                height: '40px',
                                                padding: '8px 20px',
                                                fontSize: '16px',
                                            }}
                                            onClick={handleVerifyOTP}
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div className="text-center mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            disabled={resendDisabled}
                                            onClick={handleResendOTP}
                                        >
                                            Resend OTP {resendDisabled && `(${countdown})`}
                                        </button>
                                    </div>
                                </>
                            )}
                            <div className="text-center mt-4">
                                <p className="mt-0">Don't have an account?</p>
                                <Link
                                    className="btn btn-lg btn-warning"
                                    data-toggle="tab"
                                    to="/form-register"
                                    style={{
                                        color: '#fff',
                                        height: '40px',
                                        padding: '8px 20px',
                                        fontSize: '16px',
                                        width: '40%',
                                    }}
                                >
                                    Register
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FormLogin;
