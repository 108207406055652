import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AppointmentForm = ({ userName, userPhoneNumber, userId, familyMembers, onSubmit }) => {
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [showModal, setShowModal] = useState(false); // No usage of setTokenNumber
  const [nameError, setNameError] = useState('');
  const [bookingError, setBookingError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState('morning'); // State for selected slot



  // Get today's date
  const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30); // 30 days from today
  const maxDate = futureDate.toISOString().split('T')[0];


  // Calculate tomorrow's date
  // const tomorrow = new Date();
  // tomorrow.setDate(tomorrow.getDate());
  // const tomorrowDate = tomorrow.toISOString().split('T')[0];

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!selectedMemberId && !userName) {
      setNameError('Please select a Name');
      return;
    } else {
      setNameError('');
    }

    if (!selectedDate) {
      alert('Please select a Date');
      return;
    }

    const formattedDate = new Date(selectedDate).toISOString();

    setIsSubmitting(true);

    try {
      let formData = {
        name: selectedMemberId ? familyMembers.find(member => member._id === selectedMemberId)?.name : userName,
        phone: userPhoneNumber,
        date: formattedDate,
        slot: selectedSlot,
        userId,
        familyMemberId: selectedMemberId || null,
      };

      onSubmit(formData);
    } catch (error) {
      console.error('Error booking appointment:', error);
      setBookingError('Failed to book appointment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBookingError(null);
  };

  return (
    <>
      <div className="appointment-form form-wraper">
        <h3 className="title">Book Appointment</h3>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <div style={{ position: 'relative' }}>
              <select
                className="form-control"
                value={selectedMemberId}
                onChange={(e) => setSelectedMemberId(e.target.value)}
                required
              >
                <option value="">Select Name</option>
                <option value={userId}>{userName}</option>
                {familyMembers.map((member) => (
                  <option key={member._id} value={member._id}>
                    {member.name}
                  </option>
                ))}
              </select>
            </div>
            {nameError && <div className="error-message">{nameError}</div>}
          </div>

          <div className="form-group">
            <select
              className="form-control"
              value={userPhoneNumber}
              disabled
              required
            >
              <option value={userPhoneNumber}>{userPhoneNumber}</option>
            </select>
          </div>
          <div className="form-group">
            {/* <input
              type="date"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
            /> */}
            <input
              type="date"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              min={today}
              max={maxDate}
              required
            />
          </div>
          <div className="form-group" style={{ textAlign: 'left' }}>
            <label style={{ fontWeight: 'bold', marginRight: '15px', fontSize: '16px' }}>Choose Slot:</label>
            <label style={{ marginRight: '15px' }}>
              <input
                type="radio"
                value="morning"
                checked={selectedSlot === 'morning'}
                onChange={() => setSelectedSlot('morning')}
              />{' '}
              Morning
            </label>
            <label>
              <input
                type="radio"
                value="evening"
                checked={selectedSlot === 'evening'}
                onChange={() => setSelectedSlot('evening')}
              />{' '}
              Evening
            </label>
          </div>


          <button type="submit" className="btn btn-secondary btn-lg" style={{ backgroundColor: '#FF7A00', border: 'none' }} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Book Appointment'}
          </button>
          {bookingError && <div className="error-message">{bookingError}</div>}
        </form>
      </div>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Appointment Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your appointment has been booked successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppointmentForm;
