import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Image from '../../images/signIn.png';

function ConfirmPassword() {
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [setIsOtpSent] = useState(false);

    const navigate = useNavigate();

    const handleSendOtp = () => {
        if (!phone || phone.length !== 10) {
            setError('Please enter a valid phone number.');
            return;
        }

        setIsOtpSent(true);
        setSuccessMessage('OTP sent successfully!');
        setError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Phone:', phone);
        console.log('OTP:', otp);
        navigate('/enter_otp');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            const formattedValue = value.replace(/\D/g, '').slice(0, 10);
            setPhone(formattedValue);

            const phoneRegex = /^[1-9]\d{9}$/;
            setError(phoneRegex.test(formattedValue) ? '' : 'Invalid Phone number');
        } else if (name === 'otp') {
            setOtp(value);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendOtp();
        }
    };

    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes('android');
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center" style={{ marginTop: '50px' }}>
                    {!(isAndroid || isIOS) && (
                        <div className="col-lg-6">
                            <img src={Image} alt="Doctor" className="img-fluid d-block mx-auto mb-4" style={{ maxWidth: '100%' }} />
                        </div>
                    )}
                    <div className={(isAndroid || isIOS) ? 'col-lg-12' : 'col-lg-5 col-md-8'} style={{ marginTop: (isAndroid || isIOS) ? '0px' : '6rem' }}>
                        <div className="appointment-form form-wraper mt-16" style={{ marginTop: '50px' }}>
                            <form onSubmit={handleSubmit}>
                                <h4 className="text-center mb-4">Enter Phone Number</h4>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control ${error === 'Invalid Phone number' && 'is-invalid'}`}
                                        placeholder="Phone Number"
                                        name="phone"
                                        value={phone}
                                        onChange={handleChange}
                                        onKeyPress={handleKeyPress}
                                        required
                                    />
                                    {error === 'Invalid Phone number' && <div className="invalid-feedback">{error}</div>}
                                </div>
                                 
                                <div className="form-group">
                                        <button className="btn btn-info btn-block" onClick={handleSendOtp} style={{ backgroundColor: '#fa9225', color: 'white', width: '35%', height: '45px', fontWeight: 'bold', border: 'none', padding:'0px'
                                         }}
                                        >Send OTP</button>
                                    </div>
                              
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmPassword;
