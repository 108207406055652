import axios from 'axios';
import React, { Component } from 'react';

axios.defaults.withCredentials = true;

class Token extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            filter: 'current',
            userId: null, // Initialize userId to null
        };
    }

    componentDidMount() {
        this.fetchUserId();
    }

    fetchUserId = async () => {
        try {
            const response = await axios.get('https://api-raj.trailique.ai/api/userApi/check-session', { withCredentials: true });
            const userId = response.data.user._id;
            // Store userId in state
            this.setState({ userId }, () => {
                // Fetch current appointments for the user
                this.fetchAppointments(userId);
            });
        } catch (error) {
            console.error('Error fetching user session:', error);
        }
    };

    fetchAppointments = async (userId) => {
        try {
            const response = await axios.get(`https://api-raj.trailique.ai/api/booking/appointments/${userId}/current`, { withCredentials: true });
            const appointments = response.data.map(appointment => ({
                ...appointment,
                date: this.formatDate(new Date(appointment.date)), // Format date here
                doctorName: appointment.doctorName || 'Dr. Bhavesh Jain', // Default doctor name if not provided
                symptoms: appointment.symptoms || null, // Default symptoms if not provided
            }));
            this.setState({ appointments });
        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    fetchPreviousAppointments = async (userId) => {
        try {
            const response = await axios.get(`https://api-raj.trailique.ai/api/booking/appointments/${userId}/previous`, { withCredentials: true });
            const appointments = response.data.map(appointment => ({
                ...appointment,
                date: this.formatDate(new Date(appointment.date)), // Format date here
                doctorName: appointment.doctorName || 'Dr. Bhavesh Jain', // Default doctor name if not provided
                symptoms: appointment.symptoms || null, // Default symptoms if not provided
            }));
            this.setState({ appointments });
        } catch (error) {
            console.error('Error fetching previous appointments:', error);
        }
    };

    formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    };

    handleFilterChange = (filter) => {
        const { userId } = this.state; // Get userId from state
        this.setState({ filter }, () => {
            if (filter === 'current') {
                this.fetchAppointments(userId);
            } else if (filter === 'previous') {
                this.fetchPreviousAppointments(userId);
            }
        });
    };

    getButtonStyle = (filter) => {
        const { filter: currentFilter } = this.state;
        return {
            margin: '0 5px',
            padding: '5px 15px',
            cursor: 'pointer',
            borderRadius: '5px',
            border: 'none',
            fontWeight: '500',
            backgroundColor: currentFilter === filter ? '#ff7a00' : '#DCFCFF',
            color: currentFilter === filter ? 'white' : '#031ba1',
            transition: 'all 0.3s ease',
        };
    };

    generateAndDownloadPDF = async (appointmentId) => {
        try {
            // Call the API to generate the PDF
            await axios.get(`https://api-raj.trailique.ai/api/pdf/download/${appointmentId}`, { withCredentials: true });

            // Trigger file download
            const filePath = `https://api-raj.trailique.ai/public/prescriptions/prescription_${appointmentId}.pdf`;
            window.open(filePath, '_blank');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    render() {
        const { appointments, filter } = this.state;
        const filteredAppointments = appointments.filter(appointment => appointment.status === filter).slice(0, 5);

        return (
            <div style={{ padding: '0 100px', overflowY: 'hidden' }}>
                <h1 className="text-center mb-0" style={{ marginTop: "130px", marginBottom: '0px' }}>My Appointments</h1>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <button style={this.getButtonStyle('current')} onClick={() => this.handleFilterChange('current')}>Upcoming</button>
                    <button style={this.getButtonStyle('previous')} onClick={() => this.handleFilterChange('previous')}>Previous</button>
                </div>
                <div className="table-responsive" style={{ marginTop: '50px', marginRight: 'auto', marginLeft: 'auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: '10px', overflow: 'hidden', marginBottom: '70px' }}>
                    <table className="table" style={{ borderCollapse: 'collapse', borderColor: '#d9dbda', width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                        <thead style={{ backgroundColor: '#d9dbda', color: '#273F61', textAlign: 'center' }}>
                            <tr>
                                <th style={{ padding: '8px', width: '15%', borderTopLeftRadius: '10px', color: '#273F61', fontWeight: 'bold', fontSize: '20px' }}>Date</th>
                                <th style={{ padding: '8px', width: '15%', borderTopLeftRadius: '10px', color: '#273F61', fontWeight: 'bold', fontSize: '20px' }}>Token No.</th>
                                <th style={{ padding: '8px', width: '20%', borderLeft: '1px solid #BFBFBF', color: '#273F61', fontWeight: 'bold', fontSize: '20px' }}>Dr. Name</th>
                                {filter === 'previous' && <th style={{ padding: '8px', width: '25%', borderLeft: '1px solid #BFBFBF', color: '#273F61', fontWeight: 'bold', fontSize: '20px' }}>Prescription</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAppointments.map((appointment, index) => (
                                <tr key={index} style={{ textAlign: 'center' }}>
                                    <td style={{ padding: '8px', backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>{appointment.date}</td>
                                    <td style={{ padding: '8px', backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>{appointment.token}</td>
                                    <td style={{ padding: '8px', borderLeft: '1px solid #BFBFBF', backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>{appointment.doctorName}</td>
                                    {filter === 'previous' && (
                                        <td style={{ padding: '8px', borderLeft: '1px solid #BFBFBF', backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                                            <button
                                                style={{ color: '#031ba1', background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer' }}
                                                onClick={() => this.generateAndDownloadPDF(appointment._id)}
                                            >
                                                Download Prescription
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <style jsx>{`
                    @media (max-width: 768px) {
                        .table-responsive {
                            overflow-x: auto;
                        }
                        table th {
                            padding: 5px;
                            font-size: 10px;
                        }
                        table td {
                            padding: 5px;
                            font-size: 13px;
                        }
                        button {
                            margin: 5px 3px;
                            padding: 5px 8px;
                            font-size: 14px;
                        }
                        h1 {
                            font-size: 24px;
                        }
                    }
                `}</style>
            </div>
        );
    }
}

export default Token;
