import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useSessionTimeout() {
    const navigate = useNavigate();

    useEffect(() => {
        let timeout;

        const resetTimeout = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                navigate('/form-login'); 
            }, 20 * 60 * 1000); // 20 minutes timeout

            window.addEventListener('mousemove', resetTimeout); // Reset timer on mouse move
            window.addEventListener('keypress', resetTimeout); // Reset timer on key press
        };

        resetTimeout();

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keypress', resetTimeout);
        };
    }, [navigate]);
}

export default useSessionTimeout;
