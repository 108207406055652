import React, { Component } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import Footer from "./layout/footer";
import Header from "./layout/header";
import AboutUs from './pages/about-us';
import Chat from './pages/chat';
import ConfirmPassword from './pages/confirm_password';
import EnterOTP from './pages/enter_otp';
import Error from './pages/error-404';
import FormForgetPassword from './pages/form-forget-password';
import FormLogin from './pages/form-login';
import FormRegister from './pages/form-register';
import Index from './pages/index';
import MyAppointment from './pages/myappointment';
import NewPassword from './pages/new_password';
import VideoCall from './pages/videocall';
// import Prescription from './pages/prescription';
import useRequireAuth from './../hooks/useRequireAuth';
import SignOut from './../hooks/useSignOut'; // Your sign-out component
import AppointmentUser from './pages/appointmentuser';
import BookAppointment from './pages/bookappointment';
import MyEHR from './pages/ehr';
import FamMember from './pages/fammember';
import MyProfile from './pages/myprofile';
import Services from './pages/services';
import Token from './pages/token';


class Markup extends Component {
  state = {
    doctors: [
      { id: 1, name: "Dr. Bennett", speciality: "Dermatologist", experience: 5, ratings: 4 },
      { id: 2, name: "Dr. Davis Engel", speciality: "Dentist", experience: 3, ratings: 3 },
      { id: 3, name: "Dr. Mark", speciality: "Pediatrics", experience: 8, ratings: 5 }
    ]
  };

  render() {
    return (
      <Routes>
        <Route path="/" element={<ThemeLayout />}>
          <Route index element={<Index />} />
          <Route path="home" element={<Index />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route
            path="myappointment"
            element={
              <PublicRoute>
                <MyAppointment />
              </PublicRoute>
            }
          />
          <Route
            path="myprofile"
            element={
              <PublicRoute>
                <MyProfile />
              </PublicRoute>
            }
          />
          <Route
            path="fammember"
            element={
              <PublicRoute>
                <FamMember />
              </PublicRoute>
            }
          />

          <Route
            path="ehr"
            element={
              <PublicRoute>
                <MyEHR />
              </PublicRoute>
            }
          />
          <Route
            path="videocall"
            element={
              <PublicRoute>
                <VideoCall />
              </PublicRoute>
            }
          />
          <Route
            path="chat"
            element={
              <PublicRoute>
                <Chat />
              </PublicRoute>
            }
          />
          <Route
            path="bookappointment"
            element={
              <PublicRoute>
                <BookAppointment />
              </PublicRoute>
            }
          />
          <Route
            path="token"
            element={
              <PublicRoute>
                <Token />
              </PublicRoute>
            }
          />
          <Route
            path="appointmentuser"
            element={
              <PublicRoute>
                <AppointmentUser />
              </PublicRoute>
            }
          />
          <Route
            path="none"
            element={
              <PrivateRoute>

              </PrivateRoute>
            }
          />
          <Route path="services" element={<Services />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="form-login" element={<PublicRoute><FormLogin /></PublicRoute>} />
        <Route path="form-register" element={<PublicRoute><FormRegister /></PublicRoute>} />
        <Route path="form-forget-password" element={<PublicRoute><FormForgetPassword /></PublicRoute>} />
        <Route path="confirm_password" element={<PublicRoute><ConfirmPassword /></PublicRoute>} />
        <Route path="new_password" element={<PublicRoute><NewPassword /></PublicRoute>} />
        <Route path="services" element={<PublicRoute><Services /></PublicRoute>} />
        <Route path="enter_otp" element={<PublicRoute><EnterOTP /></PublicRoute>} />
        <Route path="signout" element={<SignOut />} />
      </Routes>
    );
  }
}

function PrivateRoute({ children }) {
  const auth = useRequireAuth();
  if (auth === undefined) {
    return <div>Loading...</div>;
  }
  return auth ? children : <Navigate to="/form-login" />;
}

function PublicRoute({ children }) {
  const auth = useRequireAuth();
  if (auth === undefined) {
    return <div>Loading...</div>;
  }
  return !auth ? children : <Navigate to="/home" />;
}

function ThemeLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default Markup;
