import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../images/signUp.png';
import Footer from '../layout/footer';
import Header from '../layout/header';
axios.defaults.withCredentials = true;


const FormRegister = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: '',
    phone: '+91',
    phoneError: '',
    successMessage: '',
    errorMessage: '',
  });

  useEffect(() => {
    async function checkSession() {
      try {
        const response = await axios.get('https://api-raj.trailique.ai/api/userApi/check-session');
        if (response.status === 200 && response.data.isAuthenticated) {
          navigate('/myprofile'); // Redirect to myprofile if authenticated
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    }

    checkSession();
  }, [navigate]);

  axios.defaults.withCredentials = true;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Ensure +91 is always present
      let formattedValue = value.replace(/\D/g, '');
      if (!formattedValue.startsWith('91')) {
        formattedValue = '91' + formattedValue;
      }
      formattedValue = '+' + formattedValue.slice(0, 12);

      const phoneNumber = formattedValue.slice(3); // Remove +91 for validation
      const phoneRegex = /^[1-9]\d{9}$/;
      setState((prevState) => ({
        ...prevState,
        [name]: formattedValue,
        phoneError: phoneRegex.test(phoneNumber) ? '' : 'Invalid Phone number. It must be 10 digits long.',
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone } = state;

    // Adjust the regex to validate phone number including +91 prefix
    const phoneRegex = /^\+91[1-9]\d{9}$/;
    if (!phoneRegex.test(phone)) {
      setState((prevState) => ({
        ...prevState,
        phoneError: 'Invalid phone number. It must be 10 digits long, starting with +91.',
        errorMessage: '',
      }));
      return;
    }

    try {
      const response = await fetch('https://api-raj.trailique.ai/api/userApi/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          phoneNumber: phone,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('phoneNumber', phone);
        setState((prevState) => ({
          ...prevState,
          successMessage: 'Registration successful...!!!',
          errorMessage: '',
        }));
        localStorage.setItem('userId', data.userId);
        setTimeout(() => {
          navigate('/enter_otp');
        }, 2000);
      } else {
        setState((prevState) => ({
          ...prevState,
          errorMessage: data.error || 'Registration failed. Please try again later.',
        }));
      }
    } catch (error) {
      console.error('Network error:', error.message);
      setState((prevState) => ({
        ...prevState,
        errorMessage: 'Network error. Please try again later.',
      }));
    }
  };

  const handleKeyPress = (e, nextField) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const nextElement = document.getElementById(nextField);
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  const { name, phone, phoneError, successMessage, errorMessage } = state;

  return (
    <>
      <Header />

      <div className="container" style={{ marginTop: '60px', marginBottom: '30px' }}>
        <div className="row justify-content-center">
          <div className="col-lg-6 d-none d-lg-block">
            <img src={Image} alt="Doctor" className="img-fluid d-block mx-auto mb-4" style={{ maxWidth: '100%' }} />
          </div>
          <div className="col-lg-6 col-12" style={{ marginTop: '4rem' }}>
            <div className="appointment-form form-wraper" style={{ width: '80%', margin: '0 auto', padding: '30px 20px' }}>
              <form onSubmit={handleSubmit}>
                <h3 className="text-center" style={{ marginBottom: '20px' }}>Sign Up</h3>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    required
                    onKeyDown={(e) => handleKeyPress(e, 'phone')}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${phoneError && 'is-invalid'}`}
                    placeholder="Phone Number"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    required
                    id="phone"
                    onKeyDown={(e) => handleKeyPress(e, 'phone')}
                  />
                  {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                </div>

                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}

                <div className="form-group">
                  <button type="submit" className="btn btn-warning btn-block" style={{ color: '#fff', height: '40px', padding: '8px 20px', fontSize: '16px' }}>Sign Up</button>
                </div>

                <div className="text-center" style={{ marginBottom: '10px' }}>
                  <p>Already have an account? <Link to="/form-login">Login</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FormRegister;
