import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Image from '../../images/signIn.png';

function EnterOtp() {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const navigate = useNavigate();
    const phoneNumber = localStorage.getItem('phoneNumber');

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    clearInterval(interval);
                    setResendDisabled(false);
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api-raj.trailique.ai/api/userApi/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber,
                    otp,
                }),
            }, {
                withCredentials: true,
            });

            const data = await response.json();

            if (response.ok) {
                navigate('/myprofile');
            } else {
                setError(data.error || 'Failed to verify OTP.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error.message);
            setError('Network error occurred.');
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        const formattedOtp = value.replace(/\D/g, '').slice(0, 6);
        setOtp(formattedOtp);
        if (formattedOtp.length !== 6) {
            setError('Please enter a valid 6-digit OTP.');
        } else {
            setError('');
        }
    };

    const handleResendOTP = async () => {
        try {
            // Request OTP generation again
            const response = await fetch('https://api-raj.trailique.ai/api/userApi/resend-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber,
                }),
            }, {
                withCredentials: true,
            });

            const data = await response.json();

            if (response.ok) {
                setError('');
                setResendDisabled(true);
                setCountdown(60);
                const interval = setInterval(() => {
                    setCountdown((prevCountdown) => {
                        if (prevCountdown === 1) {
                            clearInterval(interval);
                            setResendDisabled(false);
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
                alert('OTP resent successfully!'); // Success message
            } else {
                setError(data.error || 'Failed to resend OTP.');
            }
        } catch (error) {
            console.error('Error resending OTP:', error.message);
            setError('Network error occurred.');
        }
    };

    const isMobileDevice = () => {
        return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    const containerStyle = {
        marginTop: isMobileDevice() ? '70px' : '120px'
    };

    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center" style={{ marginBottom: '30px' }}>
                    {!isMobileDevice() && (
                        <div className="col-lg-6" style={{ marginBottom: '20px' }}>
                            <img src={Image} alt="Doctor" className="img-fluid d-block mx-auto mb-4" style={{ maxWidth: '100%' }} />
                        </div>
                    )}
                    <div className="col-lg-5 col-md-8" style={containerStyle}>
                        <div className="appointment-form form-wraper mt-16">
                            <form onSubmit={handleSubmit}>
                                <h3 className="text-center mb-4">Enter OTP</h3>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter OTP"
                                        name="otp"
                                        value={otp}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                {error && <div className="alert alert-danger">{error}</div>}

                                <button type="submit" className="btn btn-block" style={{ backgroundColor: '#fa9225', color: 'white', width: '35%', padding: '0px', height: '45px', fontWeight: 'bold', border: 'none' }} disabled={otp.length !== 6}>
                                    Verify OTP
                                </button>

                                <div className="text-center mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        disabled={resendDisabled}
                                        onClick={handleResendOTP}
                                    >
                                        Resend OTP {resendDisabled ? `(${countdown})` : ''}
                                    </button>
                                </div>
                                <div className="text-center mt-4">
                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: 'white',
                                            border: 'none',
                                            color: '#FA9225',
                                            fontWeight: 'bold'

                                        }}
                                        onClick={() => navigate('/')}
                                    >
                                        Back to Home
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EnterOtp;
